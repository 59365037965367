<template>
    <!-- <Layout> -->
    <div class="px-1 mt-1">
        <div class="card mb-0">
            <div class="card-body">
                <div v-if="loading">
                    <LoadingTableForm
                        :tables="1"
                        :cols="2"
                        :rows="9"
                        class="table-bordered bg-white" />
                </div>
                <b-form v-else @submit.prevent="submitForm">
                    
                    <table
                        class="table table-bordered table-wrap table-form mb-2">
                         <colgroup>
                           <col width="15%">
                        </colgroup>
                        <thead>
                            <tr class="fs-5 bg-light bg-soft">
                                <th class="text-start" colspan="6">
                                    {{ $t('create ticket') }}
                                </th>
                            </tr>
                            <tr class="fs-5 bg-light bg-soft">
                                <td colspan="6">
                                     <div class="template-buttons">
                                        <b-button  
                                            v-for="t,i in templates" :key="t._id"
                                            variant="outline-secondary"
                                            size="sm"
                                            class="w-100"
                                            @click.prevent="useTemplate(t)">
                                        {{ t.subject}}</b-button>
                                    </div>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th
                                    class="bg-light bg-soft hidden"
                                    style="width: 11%"
                                   >
                                    {{ $t('customer') }}
                                </th>
                                <td
                                    style="width: 20%"
                                   >
                                    <input
                                        :readonly="
                                            Boolean($route.query.username)
                                        "
                                        type="text"
                                        class="form-control"
                                        :class="{
                                            'is-invalid':
                                                pl.username &&
                                                v$.pl.username.$error,
                                            'is-valid':
                                                pl.username &&
                                                !v$.pl.username.$error,
                                        }"
                                        :placeholder="`${$t('enter username')}`"
                                        v-model="pl.username"
                                        list="usernames" />

                                    <div
                                        v-if="
                                            pl.username &&
                                            v$.pl.username.validUsername
                                                .$invalid
                                        "
                                        class="invalid-feedback">
                                        {{
                                            v$.pl.username.validUsername
                                                .$message
                                        }}
                                    </div>
                                </td>
                                <th
                                    class="bg-light bg-soft hidden"
                                    style="width: 11%">
                                    {{ $t('status') }}
                                </th>
                                <td style="width: 20%">
                                    <multiselect
                                        :placeholder="`${$t('select status')}`"
                                        v-model="pl.status"
                                        :options="status_options"
                                        label="name"
                                        :searchable="false"
                                        :can-deselect="false"
                                        :can-clear="false"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.status.$error,
                                        }">
                                    </multiselect>
                                    <div
                                        v-for="(item, index) in v$.pl.status
                                            .$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <!-- <tr>
                                <th
                                    class="bg-light bg-soft hidden"
                                    style="width: 11%"
                                    v-if="pl.type == 'public'">
                                    {{ $t('level') }}
                                </th>
                                <td
                                    style="width: 20%"
                                    v-if="pl.type == 'public'">
                                    <multiselect
                                        :placeholder="`${$t('select level')}`"
                                        v-model="pl.level"
                                        :options="level_options"
                                        label="name"
                                        :class="{
                                            'is-invalid':
                                                submitted && v$.pl.level.$error,
                                        }">
                                    </multiselect>
                                    <div
                                        v-for="(item, index) in v$.pl.level
                                            .$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                            </tr> -->
                            <tr>
                                <th class="bg-light bg-soft hidden">
                                    {{ $t('category') }}
                                </th>
                                <td colspan="5">
                                    <multiselect
                                        :placeholder="`${$t(
                                            'select category'
                                        )}`"
                                        v-model="pl.category"
                                        :options="category_options"
                                        label="name"
                                        :searchable="false"
                                        :can-deselect="false"
                                        :can-clear="false"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.category.$error,
                                        }">
                                         <template v-slot:singlelabel="{ value }">
                                            <span class="flex-fill ps-2">{{
                                                $t(value.value)
                                            }}</span>
                                        </template>
                                        <template v-slot:option="{ option }">
                                            {{ $t(option.value) }}
                                        </template>
                                    </multiselect>
                                    <div
                                        v-for="(item, index) in v$.pl.category
                                            .$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft hidden">
                                    {{ $t('subject') }}
                                </th>
                                <td colspan="5">
                                    <input
                                        type="text"
                                        :placeholder="`${$t('enter subject')}`"
                                        v-model="pl.subject"
                                        class="form-control"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.subject.$error,
                                        }" />
                                    <div
                                        v-for="(item, index) in v$.pl.subject
                                            .$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <th class="bg-light bg-soft hidden">
                                    {{ $t('content') }}
                                </th>
                                <!-- <td>
                                            <div
                                                class="fw-bold text-center mb-2">
                                                {{ $t('template') }}
                                            </div>
                                            <div
                                                class="border"
                                                :style="{
                                                    maxHeight:
                                                        templateWrapperHeight -
                                                        28 +
                                                        'px',
                                                    overflow: 'auto',
                                                }">
                                                <button
                                                    type="button"
                                                    class="btn btn-outline-light w-100 mb-1 text-truncate"
                                                    v-for="t in templates"
                                                    :key="t._id"
                                                    @click.prevent="
                                                        selectTemplate(t)
                                                    ">
                                                    {{ t.subject }}
                                                </button>
                                            </div>
                                        </td> -->
                                <td colspan="3">
                                    <!-- <div ref="quillWrapper">
                                                <QuillEditor
                                                    contentType="html"
                                                    :placeholder="
                                                        $t('enter content here')
                                                    "
                                                    v-model:content="pl.content"
                                                    :class="`ql-container ql-snow ${
                                                        submitted &&
                                                        v$.pl.content.$error
                                                            ? 'is-invalid'
                                                            : ''
                                                    }`" />
                                            </div>  -->
                                    <textarea
                                        type="text"
                                        :placeholder="`${$t(
                                            'enter content here'
                                        )}`"
                                        v-model="pl.content"
                                        class="form-control"
                                        rows="7"
                                        style="resize: none"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.content.$error,
                                        }"></textarea>
                                    <div
                                        v-for="(item, index) in v$.pl.content
                                            .$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="text-end">
                        <button
                            :disabled="loading"
                            type="submit"
                            class="btn btn-warning btn-lg">
                            <i class="bx bx-send"></i>
                            {{ $t('send') }}
                        </button>
                    </div>
                </b-form>
            </div>
        </div>
    </div>
    <!-- </Layout> -->
</template>

<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import LoadingTableForm from '@/components/loading-table-form';

import Switches from 'vue-switches';
import { mapActions, mapGetters } from 'vuex';
import ui from '../../../mixins/ui';
import datetime from '../../../mixins/datetime';
import number from '../../../mixins/number';
import string from '../../../mixins/string';
import convert from '../../../mixins/convert';
import { required, requiredIf, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import Multiselect from '@vueform/multiselect';
import Swal from 'sweetalert2';
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        Layout,
        PageHeader,
        Switches,
        Multiselect,
        LoadingTableForm,
    },
    mixins: [datetime, number, string, convert,ui],
    data() {
        return {
            title: null,
            submitted: false,
            loading: false,
            status_options: [
                { name: this.$t('open'), value: 'open' },
                { name: this.$t('closed'), value: 'closed' },
            ],
            type_options: [
                { name: this.$t('private'), value: 'private' },
                { name: this.$t('public'), value: 'public' },
            ],
            level_options: [ 
                { name: this.$t('level 1'), value: 1 },
                { name: this.$t('level 2'), value: 2 },
                { name: this.$t('level 3'), value: 3 },
                { name: this.$t('level 4'), value: 4 },
                { name: this.$t('level 5'), value: 5 },
                { name: this.$t('level 6'), value: 6 },
                { name: this.$t('level 7'), value: 7 },
                { name: this.$t('level 8'), value: 8 },
                { name: this.$t('level 9'), value: 9 },
                { name: this.$t('level 10'), value: 10 },
            ],
            category_options: [
                'Inquiry of Deposit Accounts',
                'Charge and Exchange Inquiry',
                'System Error',
                'Game Error',
                'Other Inquiries',
            ],
            category_options_kr: [
                '입금계좌 문의',
                '입·출금 문의',
                '시스템 오류',
                '게임 오류',
                '기타문의',
            ],
            customer_options: [],
            pl: {
                username: null,
                level: null,
                customer_id: null,
                subject: null,
                content: null,
                type: 'private',
                status: 'closed',
                category: null,
            },
            template_id: null,
            templates: [],
            templateWrapperHeight: 0,
        };
    },
    validations() {
        return {
            pl: {
                username: {
                    validUsername: helpers.withMessage(
                        'must be a valid username',
                        function (value) {
                            return this.pl.customer_id != null;
                        }
                    ),
                },
                subject: {
                    required: helpers.withMessage('required', required),
                },
                content: {
                    required: helpers.withMessage('required', required),
                },
                type: {
                    required: helpers.withMessage('required', required),
                },
                status: {
                    required: helpers.withMessage('required', required),
                },
                category: {
                    required: helpers.withMessage('required', required),
                },
                level: {
                    // requiredIf: helpers.withMessage(
                    //     'required',
                    //     requiredIf(function () {
                    //         return this.pl.type == 'public';
                    //     })
                    // ),
                },
                customer_id: {
                    requiredIf: helpers.withMessage(
                        'required',
                        requiredIf(function () {
                            return this.pl.type == 'private';
                        })
                    ),
                },
            },
        };
    },
    watch: {
        // 'pl.content'() {
        //     this.templateWrapperHeight = this.$refs.quillWrapper.clientHeight;
        // },
        templateWrapperHeight(newVal, oldVal) {
            var addedHeight = newVal - oldVal;
            window.resizeTo(
                window.outerWidth,
                window.outerHeight + addedHeight
            );
        },
        'pl.username'() {
            this.getUsernames();
        },
    },
    methods: {
        ...mapActions('player', {
            playerGetList: 'getList',
        }),
        ...mapActions('agent', {
            agentGetList: 'getList',
        }),
        ...mapActions('ticket', {
            ticketCreate: 'create',
            templateGetList: 'templateGetList',
        }),
        async submitForm() {
            if (!this.isAuthorized()) return;
            this.submitted = true;
            this.v$.$touch();
            if (this.v$.$invalid) {
                return;
            } else {
                var fd = new FormData();
                fd.append('customer_id', this.pl.customer_id);
                fd.append('category', this.pl.category);
                fd.append('subject', this.pl.subject);
                fd.append('content', this.pl.content);
                fd.append('status', this.pl.status);
                fd.append('type', this.pl.type);
                this.loading = true;
                const res = await this.ticketCreate({
                    form_data: fd,
                });
                this.loading = false;

                if (res) {
                    if (res.success) {
                        window.opener.postMessage('reload-tickets');
                        this.reset();
                    }
                    Swal.fire({
                        title: res.success
                            ? this.$t('ticket has been created')
                            : this.$t('creating ticket failed'),
                        icon: res.success ? 'success' : 'error',
                        timer: 2000,
                    });
                }
            }
        },
        useTemplate(template) {
            this.pl.subject = template.subject
            this.pl.content = template.content
        },
        async init() {
            var pl = {
                limit: 100,
            };
            this.loading = true;
            this.templates = await this.templateGetList(pl);
            this.loading = false;
        },
        async getUsernames() {
            this.v$.pl.username.$touch();
            this.customer_options = [];
            this.pl.customer_id = null;

            var pl = {
                limit: 100,
                username: this.pl.username || null,
            };
            const users =
                this.$route.params.role == 'player'
                    ? await this.playerGetList(pl)
                    : await this.agentGetList(pl);
            users.data.forEach((r) => {
                this.customer_options.push({ name: r.username, value: r._id });
            });
            if (users.data.length === 1) {
                this.pl.username = users.data[0].username;
                this.pl.customer_id = users.data[0]._id;
            }
        },

        reset() {
            this.submitted = false;
            this.pl.level = null;
            if (!this.$route.query.username) {
                this.pl.username = null;
                this.pl.customer_id = null;
            }
            this.pl.subject = null;
            this.pl.content = null;
            this.pl.category = null;
            this.pl.type = 'private';
            this.pl.status = 'closed';
            // document.querySelector('.ql-editor').innerHTML = null;
        },
    },
    mounted() {
        this.init();
        if (this.$route.query.username) {
            this.pl.username = this.$route.query.username;
            this.pl.type = 'private';
        }
    },
};
</script>
